





















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Header extends Vue {
  @Prop({ type: Boolean }) readonly showToggleCompletedBtn?: boolean
  @Prop({ type: Boolean }) readonly showCompleted?: boolean

  get toggleCompletedBtnLabel (): string {
    return this.showCompleted
      ? this.$t('tasks.taskItems.hideCompleted').toString()
      : this.$t('tasks.taskItems.showCompleted').toString()
  }
}
